import React, { useEffect, useState } from "react";
import userService from "../services/user.service";

const GlobalPromo = () => {
  const [wsId, setWsId] = useState("");
  const [emails, setEmails] = useState(["", "", ""]);
  const [errors, setErrors] = useState(["", "", ""]);
  const [submitMessage, setSubmitMessage] = useState({ text: "", isError: false });

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    setWsId(urlSearchParams.get("ws"));
  }, []);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(email);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    const newErrors = [...errors];
    if (value && !validateEmail(value)) {
      newErrors[index] = "Please enter a valid email address";
    } else {
      newErrors[index] = "";
    }
    setErrors(newErrors);
    // Clear submit message when user starts typing
    setSubmitMessage({ text: "", isError: false });
  };

  const handleSubmit = () => {
    // Check if any non-empty email is invalid
    const hasInvalidEmails = emails.some(
      (email, index) => email !== "" && !validateEmail(email)
    );

    if (hasInvalidEmails) {
      alert("Please fix invalid email addresses before submitting");
      return;
    }

    const validEmails = emails.filter(email => email && validateEmail(email));
    if (validEmails.length > 0) {
      console.log(`${wsId}###${validEmails.join('###')}`);
      userService.referral(wsId, validEmails).then(
        (response) => {
          setEmails(["", "", ""]); // Clear all email inputs
          setSubmitMessage({ 
            text: "Emails sent successfully!", 
            isError: false 
          });
        },
        (error) => {
          setSubmitMessage({ 
            text: "Failed to send emails. Please try again.", 
            isError: true 
          });
        }
      );
    } else {
      alert("Please enter at least one valid email address");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ 
        border: "1px solid #ccc", 
        padding: "20px",
        marginBottom: "20px",
        backgroundColor: "#f9f9f9"
      }}>
        <h4>Below content will be sent to the recipients:</h4>
        <p>PartsKick is a great tool.</p>
      </div>

      {submitMessage.text && (
        <div style={{ 
          marginBottom: "15px",
          padding: "10px",
          borderRadius: "4px",
          backgroundColor: submitMessage.isError ? "#ffe6e6" : "#e6ffe6",
          color: submitMessage.isError ? "#cc0000" : "#006600"
        }}>
          {submitMessage.text}
        </div>
      )}

      {[0, 1, 2].map((index) => (
        <div key={index} style={{ marginBottom: "15px" }}>
          <label style={{ display: "block", marginBottom: "5px" }}>
            Recommend PartsKick to:
          </label>
          <input
            type="email"
            value={emails[index]}
            onChange={(e) => handleEmailChange(index, e.target.value)}
            style={{ 
              width: "300px", 
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ccc"
            }}
          />
          {errors[index] && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {errors[index]}
            </div>
          )}
        </div>
      ))}

      <button 
        onClick={handleSubmit}
        style={{
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer"
        }}
      >
        Submit
      </button>
    </div>
  );
};

export default GlobalPromo;
