import React, {useState} from "react";
import adminService from "../services/admin.service";

const ClientDiscountItem = ( {item} ) => {
    const [loading, setLoading] = useState(false);
    const handleQRClick = () => {
        setLoading(true);
        adminService.sendQRCode(item.oid).then(
            (response) => {
                setLoading(false);
                alert("QR Code sent to " + item.clientName);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(error);
                alert(_content);
                setLoading(false);
            }
        );
    };
    return (
        <tr>
            <td>{item.clientName}</td>
            <td>{item.email}</td>
            <td>{item.address}</td>
            <td>{item.city}</td>
            <td><button onClick={handleQRClick}>Send QR Code</button>
            </td>
        </tr>
    );
};

export default ClientDiscountItem;