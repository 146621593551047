import React, {useEffect, useState} from "react";
import adminService from "../services/admin.service";
import {clearMessage} from "../slices/message";
import {useDispatch} from "react-redux";
import * as ClientFilter from "./ClientFilter";
import ClientQRCode from "./ClientQRCode";
import ClientQRCodeItem from "./ClientQRCodeItem";

const ClientDiscount = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [filterName, setFilterName] = useState("");
  const [filterAddress, setFilterAddress] = useState("");
  const [filterCity, setFilterCity] = useState("");
  const [filterOwner, setFilterOwner] = useState("");
  const [displayClients, setDisplayClients] = useState([]);
  let timeout;

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);


  useEffect(() => {
    setLoading(true);

      adminService.getClients().then(
          (response) => {
              setClients(response.data);
              setDisplayClients(response.data);
              setLoading(false);
          },
          (error) => {
              const _content =
                  (error.response &&
                      error.response.data) ||
                  error.message ||
                  error.toString();
              console.log(error);

              setMessage(_content);
              setLoading(false);
          }
      );

  }, []);

const handleFilterNameChange = (event) => {
    ClientFilter.handleFilterChange(event.target.value, [setFilterName], [setFilterAddress, setFilterCity, setFilterOwner], timeout, (c) => c.clientName.toLowerCase().includes(event.target.value.toLowerCase()), clients, setDisplayClients);
}

const handleFilterOwnerChange = (event) => {
    ClientFilter.handleFilterChange(event.target.value, [setFilterOwner], [setFilterName, setFilterAddress, setFilterCity], timeout, (c) => c.email.toLowerCase().includes(event.target.value.toLowerCase()), clients, setDisplayClients);
}

const handleFilterAddressChange = (event) => {
    ClientFilter.handleFilterChange(event.target.value, [setFilterAddress], [setFilterName, setFilterCity, setFilterOwner], timeout, (c) => c.address.toLowerCase().includes(event.target.value.toLowerCase()), clients, setDisplayClients);
}

const handleFilterCityChange = (event) => {
    ClientFilter.handleFilterChange(event.target.value, [setFilterCity], [setFilterName, setFilterAddress, setFilterOwner], timeout, (c) => c.city.toLowerCase().includes(event.target.value.toLowerCase()), clients, setDisplayClients);
}


  return (
      <div>
        {loading && (
            <span className="spinner-border spinner-border-sm"></span>
        )}
        {message && (
            <div className="form-group pt-50">
              <div
                  className="alert alert-danger"
                  role="alert"
              >
                {
                  message
                }
              </div>
            </div>
        )}
        <div className="row mt-5">
          <table className="table">
            <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Owner</th>
              <th scope="col">Address</th>
              <th scope="col">City</th>
              <th scope="col">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><input type="text" value={filterName} onChange={handleFilterNameChange} /></td>
                <td><input type="text" value={filterOwner} onChange={handleFilterOwnerChange} /></td>
                <td><input type="text" value={filterAddress} onChange={handleFilterAddressChange} /></td>
                <td><input type="text" value={filterCity} onChange={handleFilterCityChange} /></td>
                <td></td>
            </tr>
            {displayClients && displayClients.length > 0 && displayClients.map((c, index) => (
                <ClientQRCodeItem key={c.oid} item={c} />
            ))}
            </tbody>
          </table>
        </div>
      </div>

  );
};

export default ClientDiscount;
