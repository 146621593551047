import React, {useEffect, useState} from "react";
import {cancelLoader, setLoader} from "../slices/spinner";
import Toaster from "../common/ToastMsg";
import {useDispatch} from "react-redux";
import InventoryService from "../services/inventory.service";

const PartsPhoto = () => {
    const dispatch = useDispatch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const inventoryId = urlSearchParams.get("inventoryId");
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        dispatch(setLoader());
        InventoryService.listThumbnails(inventoryId).then(
            (response) => {
                setUrls(response.data);
                dispatch(cancelLoader());
            },
            (error) => {
                console.log(error);
                Toaster.error({
                    title: "Failed to load photos !",
                    text: error.message
                }, {
                    position: "top-center",
                    containerId: "search"
                });
                dispatch(cancelLoader());
            }
        )

    }, []);

    // display the images in a grid layout with 4 images per row

    return (
        <div className="container">
            <div className="row">
                {urls.map((url, index) => (
                    <div className="col-md-3" key={index}>
                        <img src={url} alt="part" className="img-thumbnail"/>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default PartsPhoto;