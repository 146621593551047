import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const ConnectionStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            toast.success('Connection restored!', {
                position: "top-center",
                autoClose: 2000
            });
        };

        const handleOffline = () => {
            setIsOnline(false);
            toast.error('Connection lost!', {
                position: "top-center",
                autoClose: false
            });
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return null; // This component doesn't render anything
};

export default ConnectionStatus; 